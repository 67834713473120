<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ param.headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <template v-if="p02">
        <div>
          <div class="gray-box">
            <v-select
                class="form-inp"
                :items="allCustItem"
                item-text="CD_NM"
                item-value="CD"
                outlined
                label="회사구분"
                v-model="params.aspNewCustKey"
                hide-details="auto"
                disabled
            >
            </v-select>

            <div class="mt-3">
              <v-text-field
                  class="form-inp sm"
                  label="작성자"
                  outlined
                  v-model="FST_USER_NM"
                  hide-details="auto"
                  disabled
              >
              </v-text-field>
              <v-text-field
                  class="form-inp sm ml-2"
                  label="작성일시"
                  outlined
                  v-model="FST_BRD_DT"
                  hide-details="auto"
                  disabled
              >
              </v-text-field>
              <v-text-field
                  class="form-inp sm ml-2"
                  label="수정자"
                  outlined
                  v-model="LAST_USER_NM"
                  hide-details="auto"
                  disabled
              >
              </v-text-field>
              <v-text-field
                  class="form-inp sm ml-2"
                  label="수정일시"
                  outlined
                  v-model="LAST_BRD_DT"
                  hide-details="auto"
                  disabled
              >
              </v-text-field>
            </div>
            <div class="mt-3">
              <v-text-field
                  class="form-inp full"
                  label="제목"
                  v-model="BRD_TIT"
                  outlined
                  disabled
                  hide-details
              >
              </v-text-field>
            </div>
          </div>
          <h2 class="tit-h2 mt-3 d-flex">파일목록</h2>
          <div class="mt-3">
            <v-data-table
                class="grid-default"
                :headers="gridDataHeaders"
                :items="gridDataText"
                v-model="gridListSelected"
                item-key="index"
                hide-default-footer
                calculate-widths
                show-select
                fixed-header
                height="300px"
                dense
            >
              <template v-slot:item.DOWNLOAD={item}>
                <div>
                  <v-btn v-if="mixin_set_btn($options.name, 'btnDownload')" class="small btn-default mt-1 mb-1" @click="fileDownload(item)">다운로드</v-btn>
                </div>
              </template>
            </v-data-table>
          </div>
          <div class="mt-3 text-right">
            <template>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined class="btn-etc ml-2" @click="clickBtnDel"
              >삭제
              </v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnMody')" outlined class="btn-point ml-2" @click="clickBtnModify"
              >수정
              </v-btn>
            </template>
          </div>
          <template v-if="footerSubmit">
            <div class="text-right">
              <v-btn
                  v-if="this.mixin_set_btn(this.$options.name, 'btnSave')"
                  color="success"
                  rounded
                  small
                  @click="$emit('submit')"
              >
              </v-btn>
            </div>
          </template>
          <!-- 글 수정 모달창 -->
          <div class="ml-auto">
            <v-dialog v-if="dialogM810607P01" persistent v-model="dialogM810607P01" content-class="square-modal">
              <dialog-M810607P01
                  :headerTitle="popupTitle"
                  @hide="hideDialog('M810607P01')"
                  @Qsave="hideDialog('M810607P01')"
                  :param="M810607P01Param"
              >
              </dialog-M810607P01>
              <template v-slot:body>
              </template>
            </v-dialog>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import api from "@/store/apiUtil.js"
import {mixin} from "@/mixin/mixin.js";
import DialogM810607P01 from "@/views/page/old/M810607P01";

export default {
  name      : "MENU_M810607P02", //name은 'MENU_' + 파일명 조합
  mixins    : [mixin],
  props     : {
    param: {
      type: Object,
    },
    p02  : {
      type   : Boolean,
      default: false,
    },
  },
  components: {
    DialogM810607P01,
  },
  data() {
    return {
      popupTitle: '',

      dialogM810607P01: false,
      M810607P01Param : {},

      //dropIteam
      allCustItem: [],

      //첨부파일 그리드 설정 변수
      gridDataHeaders : [
        {text: "No", value: "index", width: "100px", align: "center", sortable: false,},
        {text: "파일명", value: "ORIGINAL_FILENAME", align: "center", sortable: false,},
        {text: "크기", value: "FILE_SIZE", width: "200px", align: "center", sortable: false,},
        {text: "다운로드", value: "DOWNLOAD", width: "200px", align: "center", sortable: false,},
        {text: "다운로드수", value: "DNLOD_CNT", width: "200px", align: "center", sortable: false,},
      ],
      gridDataText    : [],
      gridListSelected: [],

      page        : 1,
      pageCount   : 0,
      itemsPerPage: 100,
      footerSubmit: '',

      //v-model
      ASP_NEWCUST_KEY: '',
      FST_USER_NM    : '',
      FST_BRD_DT     : '',
      LAST_USER_NM   : '',
      LAST_BRD_DT    : '',
      BRD_TIT        : '',

      artMsg: {
        delChk: '삭제하시겠습니까?'
      },

    }
  },
  methods: {
    async init() {
      this.allCustItem = await this.mixin_getAllAspData();
    },

    //***************************************
    //         dbData 날짜 형식 변경
    //***************************************
    parseDateTime(data) {
      let year = [data.substr(0, 4), data.substr(4, 2), data.substr(6, 2)].join('/');
      let hour = [data.substr(8, 2), data.substr(10, 2), data.substr(12, 2)].join(':');

      return year + ' ' + hour;
    },

    //***************************************
    //            상세조회
    //***************************************
    async detailPopup() {
      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"]     = "/api/board/detail-popup/inqire  ";
      requestData.headers["METHOD"]  = "inqire";
      requestData.headers["SERVICE"] = 'board.detail-popup';
      requestData.headers["TYPE"]    = "BIZ_SERVICE";

      // sendData 세팅
      //BRD_ID||BRD_NO||ASP_NEWCUST_KEY
      requestData.sendData["ASP_NEWCUST_KEY"] = this.params.aspNewCustKey;
      requestData.sendData["BRD_NO"]          = this.params.brdNo;
      requestData.sendData["BRD_ID"]          = this.params.brdId;

      let response = await this.common_postCall(requestData);
      this.detailPopupCallback(response);
    },

    async detailPopupCallback(response) {
      console.log(response);
      let data = response.DATA[0]

      this.ASP_NEWCUST_KEY = data.ASP_NEWCUST_KEY;
      this.FST_USER_NM     = data.FST_USER_NM;
      this.FST_BRD_DT      = this.parseDateTime(data.FST_BRD_DT);
      this.LAST_USER_NM    = data.LAST_USER_NM;
      this.LAST_BRD_DT     = this.parseDateTime(data.LAST_BRD_DT);
      this.BRD_TIT         = data.BRD_TIT;

      // 첨부파일 dataList 호출
      let resList = await this.mixin_getFileList(data.FILE_GROUP_KEY);
      this.setFileList(resList)
    },

    //*********************************
    //        첨부파일 리스트 set
    //*********************************,
    setFileList(listData) {
      this.gridDataText = [];

      for (let i in listData) {
        let tempItem          = listData[i];
        tempItem["FILE_SIZE"] = this.mixin_getFileSize(listData[i].FILE_SIZE);
        tempItem["index"]     = ++i;

        this.gridDataText.push(tempItem);
      }
    },

    //*********************************
    //        첨부파일 다운로드
    //*********************************
    async fileDownload(item) {
      let busiType = "chat";   //palette, phone, chat, km, bbs
      let pathType = "images"; //publics,files,images,videos,inqrytypeimage

      await this.mixin_fileDownload(item, busiType, pathType)
    },

    //***************************************
    //            수정 Button 팝업
    //***************************************
    clickBtnModify() {
      this.popupTitle      = "글 수정"
      this.M810607P01Param = {
        flag  : 'modify',
        userId: this.$store.getters['userStore/GE_USER_ID'],
        bizCd : this.param.bizCd,
        bizNm : this.param.bizNm,
        item  : this.param.item,
      };

      this.showDialog('M810607P01')
    },

    //***************************************
    //             팝업 창 닫기
    //***************************************
    clickPopupClose() {
      this.$emit('hide')
    },

    //***************************************
    //         삭제버튼 모달 이벤트
    //***************************************
    clickBtnDel() {
      this.common_confirm(this.artMsg.delChk, this.clickDelY, null,
          this.clickDelN, null, "error");
    },

    async clickDelY() {
      let requestData = {
        headers : {},
        sendData: {}
      }

      requestData.headers["URL"]     = "/api/board/detail-popup/delete";
      requestData.headers["SERVICE"] = "board.detail-popup";
      requestData.headers["METHOD"]  = "delete";
      requestData.headers["ASYNC"]   = true;
      requestData.headers["TYPE"]    = "BIZ_SERVICE";

      requestData.sendData["BRD_ID"]          = this.params.brdId;
      requestData.sendData["BRD_NO"]          = this.params.brdNo
      requestData.sendData["PROC_ID"]         = this.$store.getters['userStore/GE_USER_ID'];
      requestData.sendData["ASP_NEWCUST_KEY"] = this.params.aspNewCustKey;

      let response = await this.common_postCall(requestData);

      if (response.HEADER.ERROR_FLAG == false) {
        this.common_alert("게시물이 삭제되었습니다.", "done");

        this.$emit('submit');
      }
      this.clickPopupClose();
    },

    clickDelN() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    clickBtnPopupClose() {
      this.$emit('hide')
    },

    showDialog(menu) {
      this[`dialog${menu}`] = true;
    },
    hideDialog(menu) {
      this[`dialog${menu}`] = false;
      this.detailPopup();
    },

  },

  mounted() {
    this.init();
    this.detailPopup();
  },

  computed: {
    params() {
      return {
        aspNewCustKey: this.param.bizCd,
        brdNo        : this.param.item.BRD_NO,
        brdId        : this.param.item.BRD_ID,
      }
    },
  },
};
</script>

<style></style>
